<template>
  <InfoTooltip category="info"> Daily precipitation flux </InfoTooltip>
</template>

<script>
import InfoTooltip from "@/components/InfoTooltip";

export default {
  name: "InfoTooltipPrecipitation",
  components: {
    InfoTooltip,
  },
};
</script>

<style scoped lang="scss"></style>
