<template>
  <InfoTooltip category="info">
    Daily evapotranspiration flux, including soil and canopy evaporation and
    plant transpiration
  </InfoTooltip>
</template>

<script>
import InfoTooltip from "@/components/InfoTooltip";

export default {
  name: "InfoTooltipEvapotranspiration",
  components: {
    InfoTooltip,
  },
};
</script>

<style scoped lang="scss"></style>
