<template>
  <InfoTooltip category="info">
    Daily surface runoff, mainly coming from low-permeability surfaces
  </InfoTooltip>
</template>

<script>
import InfoTooltip from "@/components/InfoTooltip";

export default {
  name: "InfoTooltipSurfaceRunoff",
  components: {
    InfoTooltip,
  },
};
</script>

<style scoped lang="scss"></style>
