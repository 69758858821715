<template>
  <InfoTooltip category="info">
    Daily leakage from the upper soil to groundwater
  </InfoTooltip>
</template>

<script>
import InfoTooltip from "@/components/InfoTooltip";

export default {
  name: "InfoTooltipLeakage",
  components: {
    InfoTooltip,
  },
};
</script>

<style scoped lang="scss"></style>
